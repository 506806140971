<template>
    <comp-table
        title="终端群组热销品管理"
        ref="comp_table"
        edit-key="id"
        :columns="columns"
        :table-api="'/gateway/apps/o2o/api/terminal/listHotGoodsByPage?terminalGroupId=' + $core.getUrlParam('i')"
        :delete-api="v => '/gateway/apps/o2o/api/terminal/deletehotgoods?code=&id=' + v.uuid"
        :formComponent="formComponent"
        :operate="getTableOperate"
    >
        <template slot="operate">
            <Button type="primary" style="margin-right: 10px" @click="$router.push('/terminal_group')">返回</Button>
        </template>
    </comp-table>
</template>

<script>
import CompTable from "../../../jointly/components/comp-table.vue"
import formComponent from "./form.vue"

export default {
    components: { CompTable },

    data() {
        return {
            tableApi: null,

            formComponent: formComponent,

            groups: null,

            columns: [
                {
                    title: "商品ID",
                    key: "id",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "商品名称",
                    key: "goodsName",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "BANNER图",
                    key: "goodsPicBig",
                    minWidth: 200,
                    align: "center",
                    render: (h, params) => {
                        return h("img", {
                            attrs: {
                                width: "80px",
                                height: "30px",
                                src: params.row.goodsPicBig,
                                style: "opacity:0",
                                alt: "fill",
                            },
                            on: {
                                load: e => {
                                    e.target.style.opacity = "1"
                                },
                                error: e => {
                                    e.target.style.display = "none"
                                },
                            },
                        })
                    },
                },
                {
                    title: "所属供应商",
                    key: "supplierName",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "所属群组",
                    key: "terminalGroupame",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "启禁用",
                    minWidth: 200,
                    align: "center",
                    render: (h, params) => {
                        return h("p", {}, params.row.idisplay === 1 ? "启用" : "禁用")
                    },
                },
            ],
        }
    },

    mounted() {
        this.getGroup()
    },

    methods: {
        getGroup() {
            this.$get("/gateway/apps/o2o/api/terminal/goods/getTerminalGroupOptions", {
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
            }).then(res => {
                if (res.code == 200) {
                    this.groups = res.dataList
                }
            })
        },

        /**
         * 获取表格操作菜单
         */
        getTableOperate(h, params) {
            return [
                h(
                    "DropdownItem",
                    {
                        nativeOn: {
                            click: () => {
                                const d = params.row.idisplay

                                this.$post("/gateway/apps/o2o/api/terminal/enableHotGoods", {
                                    id: params.row.id,
                                    idisplay: d === 1 ? 0 : 1,
                                }).then(res => {
                                    if (res.code == 200) {
                                        this.$Message.success((d === 1 ? "禁用" : "启用") + "成功")
                                        params.row.idisplay = d === 1 ? 0 : 1
                                    }
                                })
                            },
                        },
                        style: {
                            textAlign: "center",
                        },
                    },
                    params.row.idisplay === 1 ? "禁用" : "启用"
                ),
            ]
        },
    },
}
</script>
<style lang="less"></style>

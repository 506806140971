<template>
    <comp-form title="终端热销品" ref="comp_form" submit-api="/gateway/apps/o2o/api/terminal/savehotgoodsinfo" detail-api="/gateway/apps/o2o/api/terminal/loadhotgoodsinfo" id-key="id" :submit-before="onSubmitBefore" :data-before="onDataBefore" @on-submit="onReset">
        <template v-slot:form-box="data">
            <ElemFormItem title="TV端促销图" name="goodsPicBig">
                <elem-upload :value="data.form.goodsPicBig" @on-change="data.form.goodsPicBig = $event.value.url"></elem-upload>
            </ElemFormItem>
            <ElemFormItem title="手机端促销图" name="goodsPicBig">
                <elem-upload :value="data.form.goodsPicMobile" @on-change="data.form.goodsPicMobile = $event.value.url"></elem-upload>
            </ElemFormItem>
            <ElemFormItem title="供应商" name="supplierId">
                <Select v-model="data.form.supplierId" filterable style="width:300px">
                    <Option v-for="item in suppliers" :value="item.supplierId" :key="item.supplierId">{{ item.supplierName }}</Option>
                </Select>
            </ElemFormItem>
            <ElemFormItem title="绑定商品" name="goodsId">
                <ElemGoods :supplierId="data.form.supplierId" :value="data.form.goodsObj" @on-change="onChangeGoods($event, data.form)"></ElemGoods>
            </ElemFormItem>
            <ElemFormItem title="启禁用" name="idisplay">
                <RadioGroup v-model="data.form.idisplay">
                    <Radio :label="1">启用</Radio>
                    <Radio :label="0">禁用</Radio>
                </RadioGroup>
            </ElemFormItem>
        </template>
    </comp-form>
</template>

<script>
import CompForm from "../../../jointly/components/CompForm.vue"
import ElemUpload from "../../../jointly/components/elem-upload.vue"
import ElemFormItem from "../../../jointly/components/elem-form-item.vue"
import ElemGoods from "../../components/ElemGoods.vue"

export default {
    components: { CompForm, ElemFormItem, ElemUpload, ElemGoods },

    data() {
        return {
            goods: null,
            suppliers: null,
        }
    },

    methods: {
        /**
         * 打开表单窗口
         */
        open(id) {
            this.$refs.comp_form.open(id)
            // 无商品时加载
            !this.suppliers && this.getSupplier(this.$core.getUrlParam("i"))
        },

        getSupplier(id) {
            this.$get("/gateway/apps/o2o/api/terminal/goods/listGroupSupplierByGroupId", {
                terminalGroupId: id,
            }).then(res => {
                if (res.code == 200) {
                    this.suppliers = res.dataList
                }
            })
        },

        /**
         * 重置事件
         */
        onReset() {
            this.$emit("on-reset")
        },

        /**
         * 数据前置事件
         */
        onDataBefore(data) {
            if (data.goodsId && data.goodsName) {
                data.goodsObj = {
                    key: data.goodsId,
                    value: data.goodsName,
                }
            }

            return data
        },

        /**
         * 提交数据前置事件
         */
        onSubmitBefore(data) {
            data.oemCode = parent.vue.oemInfo.oemCode
            data.updatedBy = parent.vue.loginInfo.userinfo.custGlobalId
            data.terminalGroupId = this.$core.getUrlParam("i")
            return data
        },

        onChangeGoods(evt, form) {
            form.goodsObj = evt.value
            form.goodsId = evt.value?.key
        },
    },
}
</script>
